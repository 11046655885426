import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Container from '../components/atoms/Container/Container';
import Layout from '../components/layout';
import FancyHeading from '../components/molecules/FancyHeading/FancyHeading';
import SEO from '../components/seo';
import Anchor from '../components/atoms/Anchor/Anchor';

export default function FeedbackForm() {

  const data = useStaticQuery(
    graphql
    `
    query {
        globals: allSanitySingleGlobals {
          nodes {
            excerpt {
              asset {
                url
              }
            }
          }
        }
      }
    `,
  );

  return (
    <>
      <SEO title="Thank You" />
      <Layout>
        <Container className="bg-brand-light-grey">
          <FancyHeading headingSize="1">Thank You</FancyHeading>
          <p className="font-lato text-lg mb-16 mt-16">Thank you for submission and see the excerpt download below.</p>
          <Anchor
            href={data.globals.nodes[0].excerpt.asset.url}
            className="inline-flex items-center text-xs font-lato font-bold leading-3 px-12 py-4 border-2 border-black uppercase bg-transparent transition transition-duration-500 hover:bg-brand-red hover:border-brand-red hover:text-white"
            target="blank"
          >
            Open PDF
          </Anchor>
        </Container>
      </Layout>
    </>
  );
}